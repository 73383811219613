import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BlockAboutOne from "../components/About/BlockAboutOne";
import BlockAboutTwo from "../components/About/BlockAboutTwo";
import BaseLayout from "../components/global/BaseLayout";
import Values from "../components/About/Values";

function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="About">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="About"
          Subheader="About"
          bgimg={`${rpdata?.gallery?.[30]}`}
        />
        <BlockAboutOne />
        <Values/>
        <BlockAboutTwo />
      </div>
    </BaseLayout>
  );
}

export default About;
