import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import HeroSection from "../components/Home/HeroSection";
import BlockAboutOne from "../components/About/BlockAboutOne";
import Values from "../components/About/Values";
import Directories from "../components/Home/Directories";
import BlockHomeOne from "../components/Home/BlockHomeOne";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import { Link } from "react-router-dom";
import { ButtonContent } from "../components/global/boton/ButtonContent";
import { BsArrowBarRight } from 'react-icons/bs'
import VideoPromocional from "../components/global/VideoPromocional";

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />
        <HeroSection />
        <div className="mt-[-110px]">
          <Values />
        </div>
        <BlockHomeOne />
        <Directories />

        {/* video promocional */}

          <VideoPromocional
            title={rpdata?.dbSlogan?.[3]?.slogan}
            text={rpdata?.dbAbout?.[0]?.text}
            linkVideo={`${rpdata?.videosPromo?.[0].link}`}
            image={`${rpdata?.videosPromo?.[0].img}`}
          />


        <CounterUp />
        <BlockAboutOne />
        <div className="w-4/5 mx-auto  pb-10">
          <div className="flex flex-col  items-center md:justify-between md:items-center pb-8">
            <h3>Our Services</h3>
            <ButtonContent btnStyle='three' btnName='view all services' />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
            {
              rpdata?.dbServices?.slice(0, 6).map((serv, index) => {
                return (
                  <div key={index} className='p-5 border-[#E9E9E9] border-[1px] drop-shadow-lg flex relative'>
                    <div className="w-[50%]">
                      <img
                        src={serv.description[0].img}
                        alt="No fount"
                        className="w-full h-[180px]  object-cover"
                      />
                    </div>
                    <div className="w-[50%] flex flex-col justify-end ">
                      <img
                        src={rpdata?.dbPrincipal?.favicon}
                        alt="No fount"
                        className="w-[30%] h-[40%] absolute top-0 right-0 rounded-l-full object-cover"
                      />
                      <Link to='/services'>
                        <h5 className="px-3 text-[18px]">{serv.name}</h5>
                        <div className="flex items-center">
                          <h5 className="px-3 text-[14px] text-[#ff161a]">More Services</h5>
                          <BsArrowBarRight color="#ff161a" />
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        {/* <ServicesHome /> */}
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.gallery?.[75]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
